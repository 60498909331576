import { DocumentLocation } from '../types';

export default function getDocumentLocationDisplayName(locations: DocumentLocation[]): string {
  if (locations.includes(DocumentLocation.New)) {
    return 'Triage';
  }

  if (locations.includes(DocumentLocation.Shortlist)) {
    return 'Shortlist';
  }

  return 'Classic';
}
